@import '../../../../styles/customMediaQueries.css';

.field {
  margin-bottom: 16px;
  flex: 1;
}

.fieldWithToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.tooltipSpan {
  margin-left: -12px;
}

.tooltip {
  max-width: 90%;
}

.verified {
  width: 13px;
  height: 13px;
  padding: 2px 2px 2px 3px;
  background-color: var(--marketplaceColor);
  border-radius: 50%;
  stroke: var(--colorWhite);
  margin-left: 2px;
  margin-top: -8px;
}

.verificationPending {
  display: flex;
  flex-direction: column;
  gap: 4px;

  background: var(--marketplaceColor);
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--colorWhite);
  margin: 16px 0;
}

.extraInfo {
  composes: textSmall from global;
  color: var(--colorGrey300);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}
