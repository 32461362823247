@import '../../../../styles/customMediaQueries.css';

.sectionTitle {
  margin-top: 42px;
}

.languages {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media (--viewportMedium) {
    gap: 14px;
  }
}

.row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;

  & > button {
    min-height: 42px;
  }

  @media (--viewportMedium) {
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;

    & > div:nth-child(1) {
      flex: 1 1 calc(50% - 80px);
      min-width: 200px;
    }
    & > div:nth-child(2) {
      flex: 1 1 calc(50% - 80px);
      min-width: 150px;
    }
    & > button {
      margin-top: 33px;
      flex: 0 0 100px;
      min-height: 48px;
    }
  }
}

.removeButton {
  background-color: red;

  &:hover {
    background-color: red;
    opacity: 0.8;
  }
  &:focus {
    background-color: red;
  }
}

.addItem {
  margin: 12px 0 128px;
  width: fit-content;

  @media (--viewportLarge) {
    margin: 12px 0;
  }
}
